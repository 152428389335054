import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"
import Countries from "../../../../static/countries.json"
import P from "../../core/P"
import { FlagIcon } from "react-flag-kit";

export const Poptin = props => {
    const selectRef = useRef(null);
    const [data, setData] = useState([]);
    const [isShowDropdown, setIsShowDropdown] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const { isFalse, isTemp } = props
    // eslint-disable-next-line no-unused-vars
    const [nda, setNda] = useState(false)
    const [countryCode, setCountryCode] = useState("")
    const [message, setMessage] = useState(undefined)
    const [status, setStatus] = useState(undefined)
    const [current, setCurrent] = useState("")
    const [disabled, setDisabled] = useState(false)
    const [isRecaptcha, setIsRecaptcha] = useState(false)
    const captchaRef = useRef(null);
    const [recaptchaComponent, setRecaptchaComponent] = useState(null);

    useEffect(() => {
        const timeout = setTimeout(async () => {
          // Dynamically import the package after 9 seconds
          const { default: ReCAPTCHA } = await import('react-google-recaptcha');
          setRecaptchaComponent(<ReCAPTCHA
            sitekey="6Ld19jMnAAAAAH9Y24lduuFjNMmBZEWaTwLwxLQE"
            onChange={onChangeRecaptcha}
            onErrored={onErroredRecaptcha}
            onExpired={onExpiredRecaptcha}
            ref={captchaRef}
            className="recaptch" />);
        }, 9000); // Delay of 9 seconds
    
        return () => clearTimeout(timeout);
      }, []);

    const onChangeRecaptcha = () => {
        setIsRecaptcha(true);
    }
    const onErroredRecaptcha = () => {
        setIsRecaptcha(false);
    }
    const onExpiredRecaptcha = () => {
        setIsRecaptcha(false);
    }

    function paginate(array, page_size, page_number) {
        const start_index = (page_number - 1) * page_size;
        const end_index = start_index + page_size;
        setPageNumber(pageNumber + 1)
        return array?.slice(start_index, end_index);
    }
    useEffect(() => {
        if(!(pageNumber-1)){
            const start_index = (pageNumber - 1) * 10;
            const end_index = start_index + 10;
            setData(Countries?.slice(start_index, end_index))
            setCurrent(window.location.pathname)
            setPageNumber(pageNumber + 1)
        }
    }, [pageNumber])
    function handleScroll(event) {
        const element = event.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            let newData = paginate(Countries, 10, pageNumber);
            setData(prevData => [...prevData, ...newData]);
        }
    }
    const onClickSelectBox = () => {
        setIsShowDropdown(!isShowDropdown)
    }
    const handleKeyDownSelect = (event) => {
        if (event.key === "Enter" || event.key === " ") {
            onClickSelectBox();
        }
    };
    const handleKeyDownSelectOption = (event,item) => {
        if (event.key === "Enter" || event.key === " ") {
            onClickCountryOption(item)
        }
    };
    const onClickCountryOption = (e) => {
        setCountryCode(`${e.flag} ${e?.code} (${e?.dial_code}) `)
        setIsShowDropdown(false)
    }
    // const onBlurSelectBox = () => {
    //     setIsShowDropdown(false)
    // }
    // const changeHandler = e => {
    //     setCountryCode(e.target.value)
    //     // setCountryCode(e.label)
    // }

    // const isClose = () => {
    //     isFalse(false)
    //     sessionStorage.setItem('popUpKey', false)
    // }

    const isClose = () => {
        isFalse(false)
        const count = JSON.parse(sessionStorage.getItem('popUpCount'));

        if (count === 0) {
            sessionStorage.setItem('popUpCount', 1);
            sessionStorage.setItem('startTime', 250);
            setTimeout(() => {
                isTemp()
            }, 5000)
        }
        if (count === 1) {
            sessionStorage.setItem('popUpCount', 2);
            // sessionStorage.setItem('startTime', 350);
            sessionStorage.removeItem('startTime');
            sessionStorage.setItem('First', -1)
            sessionStorage.setItem("popUpKey", false)
            // setTimeout(() => {
            //     isTemp()
            // }, 5000)
        }
        // if (count === 2) {
        //     // sessionStorage.setItem('popUpCount', 3);
        //     sessionStorage.removeItem('startTime');
        //     sessionStorage.setItem('First', -1)
        //     sessionStorage.setItem("popUpKey", false)
        // }
        let AA = sessionStorage.getItem('startTime')
        window.setInterval(function () {
            if (AA > 0) {
                AA -= 1;
                sessionStorage.setItem('First', AA);
            }
        }, 1000);
    }
    const onSubmit = e => {
        e.preventDefault()
        setDisabled(true)
        const form = e.target
        const data = new FormData(form)
        const GCL_ID =
            JSON.parse(localStorage.getItem("gclid")) !== null &&
                JSON.parse(localStorage.getItem("gclid")).value !== null
                ? JSON.parse(localStorage.getItem("gclid")).value
                : undefined
        data.set("countryCode", countryCode)
        data.set("gclid", GCL_ID)
        data.set("nda", 0)
        data.set("companyName", "")
        data.set("formType", "PopUp Form")
        data.set("pageURL", `https://webmobtech.com` + current)
        data.set("recaptcha_token", captchaRef.current.getValue())

        // eslint-disable-next-line no-unused-vars
        const response = fetch(
            // "http://127.0.0.1:8080/api/web/contactus/contact_submit",
            "https://api.webmobtech.com/wmtblog/api/web/contactus/contact_submit",
            {
                method: "POST",
                body: data,
            }
        )
            .then(response => {
                return response.json()
            })
            .then(res => {
                if (res.meta.status === "ok") {
                    document.getElementById("contact-form").reset()
                    setNda(false)
                    sessionStorage.setItem("popUpKey", false)
                    isFalse(false)
                    navigate("/thank-you/")
                }
                setDisabled(false)
                setMessage(res.meta.message)
                setStatus(res.meta.status)
            })
    }

    const Notification = ({ status, message }) => {
        if (!status) return null
        return (
            status && (
                <div
                    className={` rounded-md ${status === "ok" ? "bg-green-50" : "bg-red-50"
                        } p-4`}
                >
                    <div className="flex">
                        <div className="ml-3">
                            <p
                                className={`text-sm leading-5 font-medium ${status === "ok" ? "text-green-800" : "text-red-800"
                                    }`}
                            >
                                {message}
                            </p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    onClick={() => setStatus(false)}
                                    className={`inline-flex rounded-md p-1.5 ${status === "ok"
                                        ? "text-green-500 focus:bg-green-100 hover:bg-green-100"
                                        : "text-red-500 focus:bg-red-100 hover:bg-red-100"
                                        }  focus:outline-none transition ease-in-out duration-150`}
                                >
                                    <svg
                                        className="h-5 w-5"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    }
    useEffect(() => {
        fetch('https://ipapi.co/json/')
            .then(function (response) {
                return response.json();
            })
            .then(function (payload) {
                //    const cus=Countries.find((val)=>val.code===payload?.country_code)
                Countries.sort((x, y) => { return x.code === payload?.country_code ? -1 : y.code === payload?.country_code ? 1 : 0; });
                //    setOptionsCountry(Countries)
                setCountryCode(`${Countries[0].flag} ${Countries[0].code} (${Countries[0].dial_code}) `)
            });
    }, [])
    return (
        <div
            className="fixed z-30 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                ></div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle lg:w-3/5 md:w-2/4 lg:max-w-screen-xl md:max-w-screen-xl max-w-full w-full p-6">
                    <div
                        id="video_close_btn"
                        className="absolute opacity-100 cursor-pointer z-30 top-2 right-2 text-shark-500 p-2"
                        aria-hidden="true"
                        onClick={() => isClose()}
                        style={{
                            fontSize: "45px",
                            lineHeight: "30px",
                            transform: "rotate(45deg)",
                        }}
                    >
                        <span>+</span>
                    </div>
                    <div>
                        <p className="text-shark-400 pb-3 block">
                            Let's connect
                        </p>
                        <P
                            title={`Your idea, our tech-brain, together let's create a robust software.`}
                            // title={`Visiting GITEX 2021? Meet us there to transform your business digitally!`}
                            className={`lg:text-2xl md:text-2xl text-shark-400 pb-4 font-medium max-w-6xl`}
                            noPad
                            noCenter
                        />
                        <div className="flex-col place-items-center md:place-items-start pb-8 hidden md:block lg:block">
                            <hr className="w-40 border-b-2 border-blue-500 bg-blue-500" />
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-2 lg:gap-4 grid-cols-1 gap-6">
                        <form
                            id="contact-form"
                            method="POST"
                            className="grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-5 lg:grid-cols-2 lg:gap-6"
                            onSubmit={e => onSubmit(e)}
                        >
                            {status && (
                                <div className="sm:col-span-2">
                                    <Notification status={status} message={message} />
                                </div>
                            )}
                            <div className="sm:col-span-2">
                                <div className="mt-1 relative  shadow-sm">
                                    <input
                                        placeholder="Full Name *"
                                        id="fullName"
                                        name="fullName"
                                        required
                                        className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150 text-sm"
                                    />
                                </div>
                            </div>
                            {/* <div className="lg:col-span-1 md:col-span-2 col-span-1"> */}
                            <div className="sm:col-span-2">
                                <div className="mt-1 relative  shadow-sm">
                                    <input
                                        placeholder="Email *"
                                        id="emailId"
                                        name="emailId"
                                        type="email"
                                        required
                                        className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150 text-sm"
                                    />
                                </div>
                            </div>
                            {/* <div className="lg:col-span-1 md:col-span-2 col-span-1">
                                <div className="mt-1 relative  shadow-sm">
                                    <input
                                        placeholder="Skype ID/WhatsApp No."
                                        id="skypeOrWhatsapp"
                                        name="skypeOrWhatsapp"
                                        className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                                    />
                                </div>
                            </div> */}
                            <div className="sm:col-span-2 flex flex-wrap">
                                <div className="lg:w-5/12 w-full mt-1 relative shadow-sm">
                                    <div className='rounded-md bg-transparent form-input py-3 px-4 flex items-center gap-2 w-full text-blue-400 border border-solid  border-blue-300 transition ease-in-out duration-150 text-sm cursor-pointer focus:border-blue-500' ref={selectRef} onClick={onClickSelectBox} role='button' tabIndex="0" onKeyDown={handleKeyDownSelect}>
                                        {countryCode ? <><FlagIcon code={countryCode?.split(" ")[1]} style={{ width: '16px', height: '13px' }} /><span>{countryCode?.slice(4)}</span></> : 'Country Code *'}
                                    </div>
                                    {
                                        isShowDropdown ?
                                            <div style={{ overflowY: 'scroll', height: '150px' }} className='absolute z-10 bg-white rounded-md select-height form-select py-3 px-4 block w-full h-full border border-solid text-blue-400 border-blue-300 transition ease-in-out duration-150 text-sm cursor-pointer' onScroll={handleScroll} onTouchEnd={handleScroll}>
                                                {data.map((item, index) => (
                                                    <span role='button' tabIndex="0" onKeyDown={(e)=>handleKeyDownSelectOption(e,item)} key={index} className='cursor-pointer flex items-center py-1' onClick={() => onClickCountryOption(item)}><FlagIcon code={item?.code} style={{ width: '16px', height: '13px' }} />&nbsp;{item?.code}&nbsp;({item?.dial_code})</span>
                                                ))}
                                            </div>
                                            : null
                                    }
                                </div>
                                {/* <div className="lg:w-5/12 w-full mt-1 relative shadow-sm">
                                    <select
                                        placeholder="Country Code *"
                                        aria-label="Country"
                                        name="countryCode"
                                        options={Countries}
                                        value={countryCode}
                                        onChange={changeHandler}
                                        style={{ color: "#6687ff" }}
                                        required
                                        className="rounded-md bg-transparent select-height form-select py-3 px-4 block w-full h-full border border-solid text-gray-400 border-blue-300 transition ease-in-out duration-150 text-sm"
                                    >
                                        {Countries.map(e => (
                                            <option
                                                className="custom_option"
                                                style={{ color: "#6687ff" }}
                                                key={Math.random()}
                                            >
                                                {e.code}&nbsp;({e.dial_code})&nbsp;{e.flag}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}

                                <div className="lg:w-7/12 w-full lg:pl-6 pl-0 mt-4 lg:mt-0">
                                    {/* <div className="w-full"> */}
                                    <div className="mt-1 relative  shadow-sm">
                                        <input
                                            placeholder="Mobile Phone Number *"
                                            // type="tel"
                                            id="contactNo"
                                            name="contactNo"
                                            required
                                            className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150 text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="mt-1 relative  shadow-sm">
                                    <textarea
                                        placeholder="Project Requirement"
                                        id="desc"
                                        name="desc"
                                        rows="2"
                                        // required
                                        className="rounded-md bg-transparent form-textarea resize-none py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150 text-sm"
                                    ></textarea>
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="mt-1 relative  shadow-sm">
                                    {recaptchaComponent}
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <span className=" inline-flex shadow-sm">
                                    <div className=" mt-5 inline-flex ">
                                        {disabled ?
                                            <button
                                                type="submit"
                                                disabled
                                                className="bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white opacity-75 cursor-not-allowed rounded"
                                            >
                                                Get a Free Quote
                                            </button> :
                                            <button
                                                disabled={!isRecaptcha ? true : false}
                                                type="submit"
                                                className={isRecaptcha ? "bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded" : "bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white opacity-75 cursor-not-allowed rounded"}
                                            >
                                                Get a Free Quote
                                            </button>
                                        }
                                    </div>
                                </span>
                            </div>
                        </form>
                        <div className="hidden lg:flex justify-center items-center self-center">
                            <img
                                className="lg:h-auto lg:w-full md:w-full md:h-auto w-full h-auto object-cover"
                                src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2023/07/lets-connect4.8.webp"
                                alt={"Support team"}
                                // title="Support team"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Poptin
