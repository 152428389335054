import React, { useState, useEffect } from "react"
// import { navigate } from "gatsby"
import Countries from "../../../../static/countries.json"
import P from "../../core/P"
import H3 from "../../core/H3"

export const PopupForm = props => {
  // eslint-disable-next-line no-unused-vars
  const [nda, setNda] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const [message, setMessage] = useState(undefined)
  const [status, setStatus] = useState(undefined)
  const [modal, setModal] = useState(false)
  const [current, setCurrent] = useState("")
  const [optionsCountry, setOptionsCountry] = useState([])
  const handlePopup = () => {
    setGitexPopup(false)
    sessionStorage.setItem("GitexForm", false)
  }
  useEffect(() => {
    setCurrent(window.location.pathname)
  }, [])
  const changeHandler = e => {
    setCountryCode(e.target.value)
  }
  const handleOption = () => {
    const test = Countries
    setOptionsCountry(test)
  }
  const { setGitexPopup } = props
  const onSubmit = e => {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)
    const GCL_ID =
      JSON.parse(localStorage.getItem("gclid")) !== null &&
        JSON.parse(localStorage.getItem("gclid")).value !== null
        ? JSON.parse(localStorage.getItem("gclid")).value
        : undefined
    data.set("countryCode", countryCode)
    data.set("gclid", GCL_ID)
    data.set("nda", 0)
    data.set("companyName", "")
    data.set("formType", "Gitex Free Pass Form")
    data.set("pageURL", `https://webmobtech.com` + current)

    // eslint-disable-next-line no-unused-vars
    const response = fetch(
      // "http://127.0.0.1:8080/api/web/contactus/contact_submit",
      "https://api.webmobtech.com/wmtblog/api/web/contactus/contact_submit",
      {
        method: "POST",
        body: data,
      }
    )
      .then(response => {
        return response.json()
      })
      .then(res => {
        if (res.meta.status === "ok") {
          document.getElementById("contact-form").reset()
          setNda(false)
          setModal(true)
        }
        setMessage(res.meta.message)
        setStatus(res.meta.status)
      })
  }

  const Notification = ({ status, message }) => {
    if (!status) return null
    return (
      status && (
        <div
          className={` rounded-md ${status === "ok" ? "bg-green-50" : "bg-red-50"
            } p-4`}
        >
          <div className="flex">
            <div className="ml-3">
              <p
                className={`text-sm leading-5 font-medium ${status === "ok" ? "text-green-800" : "text-red-800"
                  }`}
              >
                {message}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={() => setStatus(false)}
                  className={`inline-flex rounded-md p-1.5 ${status === "ok"
                    ? "text-green-500 focus:bg-green-100 hover:bg-green-100"
                    : "text-red-500 focus:bg-red-100 hover:bg-red-100"
                    }  focus:outline-none transition ease-in-out duration-150`}
                >
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }

  return (
    <div>
      <div
        className="fixed z-50 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <div className="inline-block  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8  align-middle lg:w-3/5 md:w-2/4 lg:max-w-screen-xl md:max-w-screen-xl max-w-full w-full p-6">
            <div
              id="video_close_btn"
              className="absolute right-0 opacity-100 cursor-pointer z-50 top-2 right-2 text-shark-500 p-2"
              aria-hidden="true"
              onClick={() => handlePopup()}
              style={{
                fontSize: "45px",
                lineHeight: "30px",
                transform: "rotate(45deg)",
              }}
            >
              <span>+</span>
            </div>
            {!modal && (
              <div>
                <p className="text-shark-400 pb-3 lg:block md:block hidden">
                  Let's connect
                </p>
                <P
                  title={`Get a chance to win a free pass for GITEX 2021!`}
                  className={`lg:text-2xl md:text-2xl text-shark-400 pb-4 font-medium max-w-6xl`}
                  noPad
                  noCenter
                />
                <div className="flex flex-col place-items-center md:place-items-start pb-8 hidden md:block lg:block">
                  <hr className="w-40 border-b-2 border-blue-500 bg-blue-500" />
                </div>
              </div>
            )}
            <div className="grid lg:grid-cols-2 lg:gap-4 grid-cols-1 gap-6">
              <div>
                {!modal && (
                  <form
                    id="contact-form"
                    method="POST"
                    className="grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-5 lg:grid-cols-2 lg:gap-6"
                    onSubmit={e => onSubmit(e)}
                  >
                    {status && (
                      <div className="sm:col-span-2">
                        <Notification status={status} message={message} />
                      </div>
                    )}
                    <div className="sm:col-span-2">
                      <div className="mt-1 relative  shadow-sm">
                        <input
                          placeholder="Name *"
                          id="fullName"
                          name="fullName"
                          required
                          className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        />
                      </div>
                    </div>
                    <div className="lg:col-span-1 md:col-span-2 col-span-1">
                      <div className="mt-1 relative  shadow-sm">
                        <input
                          placeholder="Business Email *"
                          id="emailId"
                          name="emailId"
                          type="email"
                          required
                          className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        />
                      </div>
                    </div>
                    <div className="lg:col-span-1 md:col-span-2 col-span-1">
                      <div className="mt-1 relative  shadow-sm">
                        <input
                          placeholder="Skype ID/WhatsApp No."
                          id="skypeOrWhatsapp"
                          name="skypeOrWhatsapp"
                          className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2 flex flex-wrap">
                        <div className="lg:w-1/3 w-1/2 lg:pr-0 pr-2 mt-1 relative  shadow-sm">
                            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
                            <select
                              placeholder="Country Code *"
                              aria-label="Country"
                              name="countryCode"
                              options={Countries}
                              value={countryCode}
                              onFocus={handleOption}
                              onChange={changeHandler}
                              style={{ color: "#6687ff" }}
                              required
                              className="rounded-md bg-transparent select-height form-select py-3 px-4 block w-full h-full border border-solid text-gray-400 border-blue-300 transition ease-in-out duration-150"
                            >
                              <option
                                value=""
                                className="custom_option"
                                style={{ color: "#6687ff" }}
                              >
                                Country *
                              </option>
                              {optionsCountry && optionsCountry.map(e => (
                                <option
                                  className="custom_option"
                                  style={{ color: "#6687ff" }}
                                  key={Math.random()}
                                >
                                  {e.code}&nbsp;&nbsp;{e.flag}
                                </option>
                              ))}
                            </select>
                        </div>
                        <div className="lg:w-2/3 w-1/2 lg:pl-6 pl-2">
                          {/* <div className="w-full"> */}
                          <div className="mt-1 relative  shadow-sm">
                            <input
                              placeholder="Mobile Phone Number *"
                              // type="tel"
                              id="contactNo"
                              name="contactNo"
                              required
                              className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                            />
                          </div>
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="mt-1 relative  shadow-sm">
                        <input
                          placeholder="Company Name"
                          id="company_name"
                          name="company_name"
                          className="rounded-md bg-transparent form-input py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="mt-1 relative  shadow-sm">
                        <textarea
                          placeholder="Notes"
                          id="desc"
                          name="desc"
                          rows="2"
                          className="rounded-md bg-transparent form-textarea resize-none py-3 px-4 block w-full border border-solid  border-blue-300 transition ease-in-out duration-150"
                        ></textarea>
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <span className=" inline-flex shadow-sm">
                        <div className=" mt-5 inline-flex ">
                          <button
                            type="submit"
                            className="bg-blue-500 relative px-6 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded"
                          >
                            Grab Yours Now
                          </button>
                        </div>
                      </span>
                    </div>
                  </form>
                )}
                {modal && (
                  <>
                    <div className="flex flex-col justify-center h-full items-start">
                      <H3 title="Thank you for sharing your details." />
                      <hr className="w-48 mt-5 border-b-2 border-blue-500 bg-blue-500" />
                      <P title="We will be reaching out to the winners of this by 10 October 2021." />
                    </div>
                  </>
                )}
              </div>
              <div
                className={`${modal
                  ? "flex justify-center items-center self-center"
                  : "lg:block hidden justify-center items-center self-center"
                  }`}
              >
                <img
                  className="lg:h-auto lg:w-full md:w-full md:h-auto w-full h-auto object-cover"
                  src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2021/09/Pop-up-Image.png"
                  alt={"Support team"}
                  // title="Support team"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupForm
